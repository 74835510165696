import Reservations from './reservations.js';
import Spinner from './spinner.js';
import {
  formatDateForPrinting,
  getHotelLocaleUrlToken,
  EventHandler
} from '../../base/utils.js';

class MyUpcomingReservations {
  constructor() {
    if ($('.my-upcoming-reservations').length > 0) {
      this.init();
    }
  }

  init() {
    Spinner.appendTo($('.my-upcoming-reservations'));

    $(document).one('getUpcomingResData:success', () => {
      this.checkForUpcoming(Reservations.userResData);
      Spinner.remove($('.my-upcoming-reservations'));
    });
    $(document).one('getResData:none', () => {
      this.noReservationsFound();
      Spinner.remove($('.my-upcoming-reservations'));
    });
    $(document).one('getResData:always', () => {
      Spinner.remove($('.my-upcoming-reservations'));
    });
  }

  checkForUpcoming(userData) {
    if (userData.reservations) {
      if (userData.reservations.current) {
        this.populateReservation(userData.reservations.current, userData.property);
        $('.reservation-listing .current-text').removeClass('hidden');
        $('.my-upcoming-reservations .reservation-listing').removeClass('hidden');
      } else if (userData.reservations.future) {
        this.populateReservation(userData.reservations.future, userData.property);
        $('.reservation-listing .next-text').removeClass('hidden');
        $('.my-upcoming-reservations .reservation-listing').removeClass('hidden');
      } else {
        this.noReservationsFound();
        EventHandler.triggerEvent('upcoming-reservation', {
          upcomingReservation: 'no'
        });
      }
    } else {
      this.noReservationsFound();
      EventHandler.triggerEvent('upcoming-reservation', {
        upcomingReservation: 'no'
      });
    }
  }

  noReservationsFound() {
    $('.my-upcoming-reservations .none-upcoming-display').removeClass('hidden');
  }

  populateReservation(resData, propData) {
    resData = resData[0] || resData;
    let propertyId = resData.rooms.brandId + resData.rooms.propertyCode;
    propData = propData[propertyId];

    let $upcomingRes = $('.my-upcoming-reservations .reservation-listing'),
      brandId = resData.rooms.brandId,
      propertyCode = resData.rooms.propertyCode,
      confirmationNumber = resData.confirmationNumber,
      firstName = resData.rooms.firstName,
      lastName = resData.rooms.lastName,
      checkInDate = resData.rooms.checkInDate,
      checkOutDate = resData.rooms.checkOutDate,
      checkInFormatted = formatDateForPrinting(checkInDate, 'weekdayCompactAlt'),
      checkOutFormatted = formatDateForPrinting(checkOutDate, 'weekdayCompactAlt'),
      propertyName = propData.propertyName,
      propertyImage = propData.imgurl || '',
      propertyPhone = propData.phone || '',
      propertyAddress = propData.propertyAddress,
      propertyCity = propData.propertyCity,
      propertyState = propData.propertyState,
      propertyPostalCode = propData.propertyPostalCode,
      fullPropertyAddress = [propertyAddress, propertyCity, propertyState, propertyPostalCode].join(', '),
      propertyMapUrl = '';

    if (propData.latitude && propData.longitude) {
      propertyMapUrl = 'https://maps.apple.com/maps';
      propertyMapUrl += '?ll=' + propData.latitude + ',' + propData.longitude;
      propertyMapUrl += '&q=' + encodeURIComponent(fullPropertyAddress);
    }

    $upcomingRes.find('img.prop-image').attr('src', propertyImage);
    $upcomingRes.find('.brand-icon-logo .logo').addClass('logo-' + brandId);
    $upcomingRes.find('.property-name').text(propertyName)
      .attr('href', getHotelLocaleUrlToken() + '/hotels/' + propertyCode);
    $upcomingRes.find('.check-in-date').text(checkInFormatted);
    $upcomingRes.find('.check-out-date').text(checkOutFormatted);
    $upcomingRes.find('.property-phone').text(propertyPhone);
    $upcomingRes.find('.property-phone.visible-xs').attr('href', 'tel:' + propertyPhone);
    $upcomingRes.find('.property-address').attr('href', propertyMapUrl);
    $upcomingRes.find('.property-address .address').text(propertyAddress);
    $upcomingRes.find('.property-address .city').text(propertyCity);
    $upcomingRes.find('.property-address .state').text(propertyState);
    $upcomingRes.find('.property-address .postal-code').text(propertyPostalCode);
    $upcomingRes.find('.view-res-confirmation-link').attr('data-first', firstName);
    $upcomingRes.find('.view-res-confirmation-link').attr('data-last', lastName);
    $upcomingRes.find('.view-res-confirmation-link').attr('data-conf', confirmationNumber);
    $upcomingRes.find('.book-again-btn').attr('href', getHotelLocaleUrlToken() + '/hotels/rooms-rates?hotel_id=' + propertyCode);
    EventHandler.triggerEvent('upcoming-reservation', {
      upcomingReservation: 'yes'
    });

    let $imgCheck = $('.my-upcoming-reservations .reservation-listing .prop-image');
    Reservations.handleFallbackImage($imgCheck, brandId);

  }
}

export default new MyUpcomingReservations();
