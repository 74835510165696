import {
  exists,
  addMobileOverflowFix,
  removeMobileOverflowFix,
  isDesktopWidth, isTabletWidth, isMobileWidth
} from '../../base/dom-utils.js';
import {
  EventHandler,
  getRetailBannerHeight
} from '../../base/utils.js';
import {
  $_PAGE_,
  brand_id
} from '../../base/vars.js';

class HeaderMobile {
  constructor() {
    this.bindDropdowns();
    this.setupViewForRetailBanner();
  }

  setupViewForRetailBanner() {
    if (isTabletWidth() || isMobileWidth()) {
      let dropdownMargin;
        if ((brand_id == 'wr' && $_PAGE_.is('.homepage-template')) && exists('#smartbanner')){
          dropdownMargin = $('#smartbanner').height() + getRetailBannerHeight();
        }else{
          dropdownMargin = getRetailBannerHeight();
        }
      EventHandler.on('retailbanner:loaded', () => {
        $('header.mobile-nav .dropdown').css('marginTop', dropdownMargin);
      });
    }
  }

  bindDropdowns() {
    $(document).on('click', 'header.mobile-nav .top-nav-item', (e) => {
      if (!isDesktopWidth()) {
        let $headerMobileNav = $('header.mobile-nav'),
          $toggle = $(e.currentTarget),
          dropdownName = $(e.currentTarget).data('dropdown'),
          open = $(e.currentTarget).hasClass('open');

        this.closeDropdowns();
        $('header.mobile-nav .panel-main').show();

        if (!open) {
          let $dropdown = $headerMobileNav.find('.dropdown[data-dropdown=' + dropdownName + ']');

          if (isMobileWidth() && dropdownName == 'book') {
            $dropdown.css('marginTop', 0);
          }
          $dropdown.slideDown('fast', () => {
            $toggle.addClass('open');
            $dropdown.attr('aria-expanded', true);
            addMobileOverflowFix();
            if (dropdownName !== 'book') {
              // Booking dropdown uses iOS auto-scroll on input focus. Don't override it.
              window.scrollTo(0, 0);
            }
          });

          $dropdown.trigger('wr:dropdown:open');
        }
      }
    });
  }

  closeDropdowns() {
    let $headerMobileNav = $('header.mobile-nav'),
      $dropdowns = $headerMobileNav.find('.dropdown:visible'),
      $topNavItem = $headerMobileNav.find('.top-nav-item');

    removeMobileOverflowFix();
    $topNavItem.removeClass('open');

    // jQuery doesn't pass the element reference back here; need to use "this"
    $($dropdowns).slideUp('fast', function() {
      $(this).attr('aria-expanded', false);
      $(this).trigger('wr:dropdown:closed');
      $dropdowns.removeClass('panel-open');
      $headerMobileNav.find('.panel-current').removeClass('panel-current');
    });
  }
}

export default new HeaderMobile();
