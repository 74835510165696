import Form from '../base/form.js';
import Spinner from './spinner.js';
import UserHandler from '../base/user.js';
import { isiOS, EventHandler, getJsonObject } from '../../base/utils.js';
import { CID_COOKIE, _LOCALE_, requestUrls } from '../../base/vars.js';
import Analytics from '../../base/wyn-analytics-module.js';
import ConfigsUtils from '../../base/aem-configs/config-utils.js';
import { getSessionStorage } from '../../base/session-handler.js';

class PreferencesForm {
  constructor() {
    this.formSelector = '#preferencesUpdate';
    this.$form = $(this.formSelector);

    // Analytics variables
    this.analyticsVars = {
      hotelBrandSelection: '',
      earningSelection: '',
      travelPartner: '',
      subscribeEstatements: '',
      wyndhamRewardsPartnerOptIn: ''
    };

    if (this.$form.length > 0) {
      this.$form.show();
      this.partnersInit();
      this.formInit();
    }
    var flyerLabel = $("label[for='flyerNum']");
    flyerLabel.css('color', '#666666');
    $("#flyerNum").on("keyup",function(){
      $('#pref-error-message').css('display', 'none');
      flyerLabel.css('color', '#666666');
    });
  }

  loadPartners() {
    const partners = ConfigsUtils.getAirlineMilesPartners();
    const europeanPartnerList = partners && partners.europeanPartnerList ? partners.europeanPartnerList : [];
    const defaultPartnerList = partners && partners.defaultPartnerList ? partners.defaultPartnerList : [];
    const europeanPartnerLocales = ['de-de', 'en-gb'];
    const travelPartnerSelect = $('#travelPartner');

    if (europeanPartnerList && europeanPartnerLocales.includes(window._LOCALE_)) {
      $(europeanPartnerList).each((i, partner) => {
        travelPartnerSelect.append(new Option(partner.description, partner.partnerProgramCode));
      });
    } else if (defaultPartnerList) {
      $(defaultPartnerList).each((i, partner) => {
        travelPartnerSelect.append(new Option(partner.description, partner.partnerProgramCode));
      });
    }
    if (isiOS()) {
      travelPartnerSelect.append('<optgroup label="" class="ios-wa"></optgroup>');
    }
  }

  partnersInit() {
    this.loadPartners();
  }

  checkSMSEligibility() {
    const smsMarketing = $('.sms-marketing'),
      smsPersonalizationConfigs = ConfigsUtils.getSMSPersonalizationDisabled();
    let country = $('[data-binding="addresses.0.countryCode"]'),
      eligibleCountries = [];
    if (getSessionStorage(CID_COOKIE)) {
      const user = getSessionStorage(CID_COOKIE);
      country = user.addresses[0].countryCode;
    }
    if (!smsPersonalizationConfigs || !country) {
      return;
    }

    eligibleCountries = smsPersonalizationConfigs.attributes['sms-personalization-eligible-countries'];
    if (eligibleCountries.includes(_.isString(country) ? country : country.val()) && _LOCALE_ == 'en-us') {
      smsMarketing.removeClass('hidden');
    } else {
      smsMarketing.addClass('hidden');
    }
  }

  formInit() {
    this.$form
      .on('init.form.fv', (e, data) => {
        Form.removeFormError($(e.target));
        this.checkSMSEligibility();
      })
      .formValidation({
        excluded: ':hidden',
        button: {
          selector: '.submit',
          disabled: ''
        },
        live: 'enabled',
        fields: {
          'hotel-brand': {},
          'preferredLanguage': {
            validators: {
              notEmpty: {
                enabled: true
              }
            }
          },
          'optIn': {},
          'preferredSMSMarketing': {},
          'preferred': {},
          'preferredThirdParty': {},
        }
      })
      .on('success.form.fv', (e) => {
        Analytics.satelliteTracker('smsOptIn');
        e.preventDefault();
        let data = this.createFormData();
        this.postForm(data);
      })
      .on('err.field.fv', Form.handleMultipleErrorLabels);
  }

  createFormData() {
    const radioInputs = this.$form.find(':radio'),
      checkboxInputs = this.$form.find(':checkbox'),
      selectInputs = this.$form.find('input, select'),
      EARN_POINTS = 'earnPoints',
      EARN_AIRFARE = 'earnAirfare',
      travelPartner = $('#travelPartner').val(),
      flyerNumber = $('#flyerNum').val();

    let data = window.User,
      pointsMethod = EARN_POINTS;

    data = {
      ...data,
      updatePreferences: true,
      updateCustomer: false,
      updateAddress: false,
      updateAlias: true,
      updateEmail: false,
      memberIdentifier: data.accountNumber
    };

    const partnerIndex = _.findIndex(data.aliases, a => a.typeCode === 'PartnerPreferred');
    const hasPartner = partnerIndex != -1;

    // add radio inputs to data
    $.each(radioInputs, (i, e) => {
      if ($(e).prop('checked')) {
        pointsMethod = $(e).attr('id');
      }
    });

    if (pointsMethod == EARN_AIRFARE) {
      // update if alias exists
      let currentAlias = data.aliases[partnerIndex];
      if (hasPartner) {
        currentAlias.method = 'update';
        currentAlias.partnerProgramCode = travelPartner;
        currentAlias.alias = flyerNumber;
      } else { //add if no alias exists
        const aliasData = {
          method: 'add',
          typeCode: 'PartnerPreferred',
          partnerProgramCode: travelPartner,
          alias: flyerNumber
        };
        data.aliases = [...data.aliases, aliasData];
      }
      localStorage.setItem('PREF_POINTS_MILES', EARN_AIRFARE);
    } else if (pointsMethod == EARN_POINTS) {
      if (hasPartner) {
        // delete if alias exists
        let currentAlias = data.aliases[partnerIndex];
        currentAlias.method = 'delete';
      }

      // see if previous pref exists
      const hotelPreferenceIndex = _.findIndex(data.preferences, p => p.preferenceRuleCode === 'BRAND_PREF');
      const hasHotelPreference = hotelPreferenceIndex != -1;

      if (hasHotelPreference) {
        data.preferences[hotelPreferenceIndex].value = $('#hotelBrand').val();
      } else {
        const pref = {
          preferenceRuleCode: 'BRAND_PREF',
          preferenceRuleDetailCode: 'BRAND_PREF_TYPE',
          value: $('#hotelBrand').val()
        };
        data.preferences = [...data.preferences, pref];
      }
      localStorage.setItem('PREF_POINTS_MILES', EARN_POINTS);
    }

    // add checkbox inputs to data
    $.each(checkboxInputs, (i, e) => {
      const preferenceIds = ['preferred', 'preferredThirdParty', 'preferredSMSMarketing'];
      if (preferenceIds.includes($(e).attr('id'))) {
        const ruleDetailCode = $(e).data('binding')
          .split('.')[1];
        let index = _.findIndex(data.preferences, p => p.preferenceRuleDetailCode === ruleDetailCode);
        if (index != -1) {
          data.preferences[index].value = $(e).prop('checked').toString();
        }
      }
    }, data);

    // add select inputs to data
    $.each(selectInputs, (i, e) => {
      if ($(e).attr('id') === 'preferredLanguage') {
        const ruleDetailCode = $(e).data('binding');
        let index = _.findIndex(data.preferences, (p) => {
          return p.preferenceRuleDetailCode === ruleDetailCode
            .split('.')[1];
        });
        if (index != -1) {
          data.preferences[index].value = e.value;
        } else {
          // see if previous lang exists
          const langPreferenceIndex = _.findIndex(data.preferences, p => p.preferenceRuleCode === 'CP_LANG_PREF');
          const hasLangPreference = langPreferenceIndex != -1;

          if (hasLangPreference) {
            data.preferences[langPreferenceIndex].value = e.value;
          } else {
            const langPref = {
              preferenceRuleCode: 'CP',
              preferenceRuleDetailCode: 'CP_LANG_PREF',
              value: e.value
            };
            data.preferences = [...data.preferences, langPref];
          }               
        }
      }
    }, data);
    return data;
  }

  postForm(data) {
    data = JSON.stringify(data);
    Spinner.appendTo(this.$form);
    Form.disableFormField(this.$form.find('.submit'));
    Form.disableFormField(this.$form.find('.cancel'));

    $.ajax({
      type: 'POST',
      contentType: 'application/json',
      url: requestUrls['update'],
      data,
      context: {
        onFail: {
          scrollToElement: this.$form,
          formElement: this.$form,
          errorElement: $('.preferred-rewards-selection')
        }
      },
      success: () => {
        Spinner.remove(this.$form);
        $('#pref-error-message').css('display', 'none');
        var label = $("label[for='flyerNum']");
        label.css('color', '#666666');
        Form.showSuccessMessage(this.$form);
        UserHandler.getProfile(() => Form.disableFormField(this.$form.find('.submit')));
        EventHandler.triggerEvent('preferences-complete', {
          pageName: 'preferences',
          subscribeEstatements: Analytics.processSubscribeEStatements(this.analyticsVars.subscribeEstatements),
          wyndhamRewardsPartnerOptIn: Analytics.processSubscribeEStatements(this.analyticsVars.wyndhamRewardsPartnerOptIn),
          earningSelection: this.analyticsVars.earningSelection,
          hotelBrandSelection: this.analyticsVars.hotelBrandSelection,
          travelPartner: this.analyticsVars.travelPartner
        });
      },
      error: (errResponse) => {
        const err = getJsonObject(errResponse);
        if (err && err.responseJSON && err.responseJSON.ErrorMessage){
          var label = $("label[for='flyerNum']");
          label.css('color', '#e00000');
          $('#pref-error-message').css('display', 'block');
          $('#pref-error-message').html(DOMPurify.sanitize(err.responseJSON.ErrorMessage));
          $('.notification-banner').css('display', 'none');
          $('.notification-banner').remove();
        }        
      },
      complete: () => {
        Form.enableFormField(this.$form.find('.cancel'));
      }
    });
  }
}

export default new PreferencesForm();
