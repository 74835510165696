import { isDesktopWidth } from '../../base/dom-utils.js';
import { RetailBannerUtils, EventHandler } from '../../base/utils.js';
import { default as WTHeader } from '../../components/header.js';
import GoogleAPIHandler from '../../base/google-api-handler.js';

class Header {
  constructor() {
    if ($('header.desktop-nav').length > 0) {
      this.bindDropdowns();
      this.bindDropdownClose();
      WTHeader.retailBanner();
      EventHandler.one('retailbanner:loaded', () => RetailBannerUtils.slideDownRetailBanner());
    }
  }
  bindDropdowns() {
    $(document).on('click', 'header.desktop-nav .top-nav-item', (e) => {
     if(!GoogleAPIHandler.autoCompleteService)
       GoogleAPIHandler.initializeData(true);
    //  GoogleAPIHandler.loadedPromise.then(() => {
    //    this.openDropdown(e);
    //  });
     this.openDropdown(e);
    });
    $(document).on('keyup', 'header.desktop-nav .top-nav-item', (e) => {
      if (e.keyCode == 13 || e.keyCode == 32) {
        this.openDropdown(e);
      }
    });
    $(document).on('keyup', (e) => {
      if (e.keyCode == 27) {
        this.closeDropdowns();
      }
    });
  }
  openDropdown(e) {
    if (isDesktopWidth()) {
      let $headerLink = $(e.currentTarget),
        $headerOpen = $('header.desktop-nav').hasClass('menu-dropdown-open'),
        $currentDropdown = $('.dropdown[data-dropdown=' + $(e.currentTarget).data('dropdown') + ']'),
        $currentDropdownOpen = $(e.currentTarget).hasClass('open');

      if (!$currentDropdownOpen && $headerOpen) {
        $('header.desktop-nav .top-nav-item').removeClass('open');
        $('header.desktop-nav .dropdown:not(.child-age-selector)').attr('aria-expanded', false)
          .hide()
          .trigger('wr:dropdown:closed');
        $headerLink.addClass('open').parents('header.desktop-nav')
          .find($currentDropdown)
          .show();
        $currentDropdown.attr('aria-expanded', true);
        $currentDropdown.trigger('wr:dropdown:open');
      } else if (!$currentDropdownOpen) {
        $('header.desktop-nav').after('<div class="dropdown-overlay-desktop"></div>');
        $headerLink.addClass('open').parents('header.desktop-nav')
          .find($currentDropdown)
          .fadeIn();
        $currentDropdown.attr('aria-expanded', true);
        $currentDropdown.trigger('wr:dropdown:open');
        $('header.desktop-nav').addClass('menu-dropdown-open');
      } else {
        this.closeDropdowns();
      }
    }
  }
  closeDropdowns() {
    let dropdowns = $('.desktop-nav').find('.dropdown:not(.child-age-selector)');

    $('.dropdown-overlay-desktop').remove();
    $('header.desktop-nav .top-nav-item').removeClass('open');
    $(dropdowns).attr('aria-expanded', false)
      .trigger('wr:dropdown:closed')
      .hide();
    $('header.desktop-nav').removeClass('menu-dropdown-open');
  }
  bindDropdownClose() {
    let _disableClose = false;
    $('header').on('dropdown:open', () => {
      _disableClose = true;
    })
      .on('dropdown:closed', () => {
        _disableClose = false;
      });

    $(document).on('click', '.dropdown-overlay-desktop, .dropdown-wrapper', (e) => {
      e.stopPropagation();

      if (_disableClose) {
        return;
      }

      if (isDesktopWidth() && $(e.target).is('.dropdown-overlay-desktop, .dropdown-wrapper')) {
        this.closeDropdowns();
      }
    });

    $(document).on('forceCloseDropdowns', (e) => {
      e.stopPropagation();

      if (isDesktopWidth()) {
        this.closeDropdowns();
      }
    });
  }
}

export default new Header();
