
import {
  BBLoad,
  BBExpandCollapse
} from '../../components/booking-bar.js';
import HeaderMobile from './header-mobile.js';
import Header from './header.js';
import {
  ResizeHandler,
  isDesktopWidth,
  isMobileWidth,
  isTabletWidth
} from '../../base/dom-utils.js';

class BookingBar {
  constructor() {
    BBLoad.bookingBarLoad();

    this._el = '.booking-bar-form.booking-bar-main';
    this.$bb = $(this._el);

    if ($('.extended-hero-container').length) {
      this.$extendedHero = $('.extended-hero-container');
    } else {
      this.$extendedHero = $('.extended-hero-component');
    }

    this.reservationsDropdownDesktop = '.dropdown[data-dropdown=reservations]';
    this.$reservationsDropdownDesktop = $(this.reservationsDropdownDesktop);
    this.reservationsDropdownMobile = '.dropdown[data-dropdown=book]';
    this.$reservationsDropdownMobile = $(this.reservationsDropdownMobile);
    this.$reservationsDropdowns = $(`${this.reservationsDropdownDesktop},${this.reservationsDropdownMobile}`);

    this.$reservationsDropdowns.on('wr:dropdown:open', this.handleBookingBarInHeader.bind(this))
      .on('wr:dropdown:closed', this.putBookingBarIntoExtendedHero.bind(this));

    if (this.$extendedHero.is('.has-booking-bar')) {
      this.handleExtendedHero();

      ResizeHandler.addResizeEndFn(() => {
        Header.closeDropdowns();
        HeaderMobile.closeDropdowns();

        if (isDesktopWidth() || isTabletWidth()) {
          this.putBookingBarIntoExtendedHero();
        } else {
          this.putBookingBarIntoMobileDropdown();
        }
      });
    }

    // WRM-4141
    //  jQuery UI and Bootstrap Dropdowns are not playing nice together
    //    Bootstrap Dropdowns are preventing propagation of the click to the document root
    //      Presumably this is how dropdown closing is handled, or something
    //    However, jQuery needs to capture a click on the doc root in order to reset a boolean
    //      that captures whether an element was clicked or not
    //    By firing a click on the document when the jQuery UI element in question is clicked
    //      we can validly reset the jQuery boolean value without closing the Bootstrap Dropdown.
    $('.dropdown form').on('click', '.ui-menu-item', () => {
      $(document).click();
    });
  }

  putBookingBarIntoExtendedHero() {
    let hero = this.$extendedHero;
    if (this.$extendedHero.parents('.authenticationcontainer').length) {
      // Authentication container has multiple extended hero components.
      // Limit to the visible one to avoid breaking dropdowns
      hero = this.$extendedHero.filter(':visible');
    }

    hero = hero.filter('.has-booking-bar');

    // show RTP Awareness component (would not impact the desktop visibility of the component)
    this.$bb.find('li.rtp-awareness').closest('div').show();

    if (!(isMobileWidth()) && this.$bb.parents('.modal.in').length === 0) {
      hero.append(this.$bb.detach());
    }
  }

  putBookingBarIntoDesktopDropdown() {
    BBExpandCollapse.collapse();

    this.$reservationsDropdownDesktop.find('.mm-booking-form-container').append(this.$bb.detach());
  }

  putBookingBarIntoMobileDropdown() {
    BBExpandCollapse.collapse();

    // hide RTP Awareness component on the modal
    this.$bb.find('li.rtp-awareness').closest('div').hide();

    this.$reservationsDropdownMobile.find('.book-redeem-wrapper').append(this.$bb.detach());
    if (isMobileWidth()) {
      this.$bb.find('[name="destination"]:visible').focus();
    }
  }

  handleBookingBarInHeader() {
    if (isDesktopWidth()) {
      this.putBookingBarIntoDesktopDropdown();
    } else {
      this.putBookingBarIntoMobileDropdown();
    }
  }

  handleExtendedHero() {
    this.putBookingBarIntoExtendedHero();
    $(document).on('user:profile:updated', this.putBookingBarIntoExtendedHero.bind(this));
  }
}

export default new BookingBar();
