import Spinner from './spinner.js';
import {
  $_PAGE_,
  _LANGUAGE_,
  _LOCALE_,
  _REGION_,
  requestUrls,
  ACCESS_TOKEN,
  REFRESH_TOKEN
} from '../../base/vars.js';
import ConfigsUtils from '../../base/aem-configs/config-utils.js';
import { CookieHandler } from '../../base/utils.js';

class MyCheckV3 {

  getMyCheckParams() {
    this.$el = $('.mycheck-component');
    Spinner.appendTo(this.$el);
    if (!CookieHandler.getCookie(ACCESS_TOKEN) || !CookieHandler.getCookie(REFRESH_TOKEN)) {
      console.error('missing access and refresh token for myCheck');
      return;
    }
    
    $.ajax({
      url: requestUrls.myCheckToken,
      type: 'GET',
      contentType: 'application/json',
      success: (res) => {
        this.refreshToken = res.refreshToken;
        this.publishableKey = this.$el.data('pubkey');
        this.myCheckInit();
      },
      complete: () => {
      }
    });
  }


  loadMyCheckScript() {

    if(!$_PAGE_.is('.my-profile-template')){
      let mycheckPaymentDetails = $("#mycheckPaymentDetails").clone();
      $("#mycheckPaymentDetails").remove();
      $("body").append(mycheckPaymentDetails);
    }

    let MyCheckConfig = ConfigsUtils.getMycheckConfiguration();
    if (!MyCheckConfig.MyCheckScriptUrl) {
      return;
    }

    let MyCheckStyleTag = document.createElement("link");
    MyCheckStyleTag.setAttribute("href", MyCheckConfig.MyCheckStyleUrl);
    MyCheckStyleTag.setAttribute("rel", 'stylesheet');
    document.head.appendChild(MyCheckStyleTag);

    let scriptTag = document.createElement("script");
    scriptTag.setAttribute("src", MyCheckConfig.MyCheckScriptUrl);
    document.body.appendChild(scriptTag);

    scriptTag.addEventListener("load", () => {
      if(ConfigsUtils.getMychecktV3Enabled()){
        console.log('Mycheck V3 script  Ready');
        this.getMyCheckParams();
      }
    }, false);
  }

  myCheckInit() {
    $('.payment-method').click(() => {
      $("#mycheckPaymentDetails").modal('show');
    });

    this.$el = $('.mycheck-v3-component');
    if (ConfigsUtils.getMychecktV3Enabled() && $('#mycheck-wallet-v3').children().length == 0) {

      window.mycheck.auth.connect({
        publishableKey: this.publishableKey,
        refreshToken: this.refreshToken
      });
      let myCheckLang;
      if (_LANGUAGE_ === _REGION_ || _LOCALE_ === 'ko-kr') {
        myCheckLang = _LANGUAGE_.toUpperCase();
      }
      if (_LOCALE_ === '') {
        myCheckLang = (_LANGUAGE_ + _REGION_).toUpperCase();
      }

      myCheckLang = ConfigsUtils.getMycheckV3LanguageValue(_LOCALE_.split('-')[0].toLowerCase());

      window.mycheck.wallet.init({
        'settings': {
          'locale' :  myCheckLang || 'en',//optional-default is en (language code in ISO 639-1)
          'merchant_id':'', //required
          'view': 'MANAGE' //required - can be either CHECKOUT or MANAGE
        }});

      $('.payment-method').removeClass('disabled');
      Spinner.remove(this.$el);


      window.mycheck.wallet.on("addCardFormReady",() =>{
        console.log('form is ready');
        setTimeout(() => {
          window.mycheck.wallet.submitCardForm();
        }, 1000);
      });

      window.mycheck.wallet.on("ready",() =>{
        $('#mycheck-wallet-v3').css({'max-height':'none'});
      });

    }
  }

  myCheckDefined() {
    return typeof (window.mycheck) !== 'undefined';
  }
}

export default new MyCheckV3();
